
import Vue from 'vue' 
import * as VueGoogleMaps from 'vue2-google-maps' 
//import pinSvg from '../img/Marker.svg'

// https://github.com/parcel-bundler/parcel/issues/2936#issuecomment-620025705
// fix issue with parcel cssloader
// update JSPackager.js file then run -> yarn patch-package parcel-bundler
Vue.use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyCQPR2BgUtCejn47ZVJQIWLev4T7kqLjrE', 
    }, 
})
Vue.component('google-map', VueGoogleMaps.Map); 


export default function LittleMap() {  

    return new Vue({
        el:"#littleMap",
        data:{ 
            currentLocation : { lat : 0, lng : 0},
            zoom: 5, 
            address:''
        },
        computed:{
            google: VueGoogleMaps.gmapApi,
            mapOptions(){ 
                return { 
                    streetViewControl: false,
                    mapTypeControl: false,
                    zoomControl: false,
                    scaleControl: false,
                    fullscreenControl: false
                }
            },
            searchLocation: function() {
                var geocoder = new google.maps.Geocoder();
               return geocoder.geocode({'address': this.address}, (results, status) => {
                  if (status === 'OK') {
                    this.currentLocation.lat = results[0].geometry.location.lat();
                    this.currentLocation.lng = results[0].geometry.location.lng();
                  }
                });
              } 
            
        }, 
        methods:{
           
        },
        mounted () {  
            this.address = this.$el.getAttribute('data-address'); 
        }
    })
    

}