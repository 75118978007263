import debounce from 'lodash.debounce'

function setHeaderType(nav) {
    let scrollYpos = window.scrollY
            
    if (scrollYpos > 160) {
        nav.classList.add('floating-header')
    } else {
        nav.classList.remove('floating-header')
    }
}

export default function nav() {
    let nav = document.querySelector('.header')
    let navSpacer = document.querySelector('.header__spacer')
    window.onload = () => setHeaderType(nav)
    
    if (document.querySelector('#page')) {
        window.addEventListener('scroll', debounce(() => {
            setHeaderType(nav)
        }, 20), true) 
    } else {
        nav.classList.add('floating-header')
        navSpacer.classList.add('d-none')
    }
}
