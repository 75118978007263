
import { post } from 'jquery'
import Vue from 'vue' 
import * as VueGoogleMaps from 'vue2-google-maps'
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import pinSvg from '../img/Marker.svg'

// https://github.com/parcel-bundler/parcel/issues/2936#issuecomment-620025705
// fix issue with parcel cssloader
// update JSPackager.js file then run -> yarn patch-package parcel-bundler

Vue.use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyCQPR2BgUtCejn47ZVJQIWLev4T7kqLjrE',
      libraries: 'places', 
    }, 
})
Vue.component('google-map', VueGoogleMaps.Map); 
export default function VenuesListing(apiRootEndpoint) { 

    const dataVenues = JSON.parse(document.getElementById('dataVenues').innerHTML);
    return new Vue({
        el: "#venues",
        components: {
            GmapCluster
        },
        props: {
            mapStyles: {
                type: Array,
                default() {
                    return [
                        {
                            featureType: 'all',
                            elementType: 'labels.text.fill',
                            stylers: [{ saturation: '0' }, { color: '#555555' }, { lightness: '0' }, { gamma: '1' }],
                        },
                        {
                            featureType: 'all',
                            elementType: 'labels.text.stroke',
                            stylers: [{ visibility: 'on' }, { color: '#ffffff' }, { lightness: 16 }],
                        },
                        { featureType: 'all', elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
                        {
                            featureType: 'administrative',
                            elementType: 'geometry.fill',
                            stylers: [{ color: '#fefefe' }, { lightness: 20 }],
                        },
                        {
                            featureType: 'administrative',
                            elementType: 'geometry.stroke',
                            stylers: [{ color: '#fefefe' }, { lightness: 17 }, { weight: 1.2 }],
                        },
                        {
                            featureType: 'administrative.country',
                            elementType: 'labels.text.fill',
                            stylers: [{ color: '#555555' }],
                        },
                        {
                            featureType: 'landscape',
                            elementType: 'geometry',
                            stylers: [{ color: '#f4f5f6' }, { lightness: 20 }],
                        },
                        { featureType: 'poi', elementType: 'geometry', stylers: [{ color: '#f4f5f6' }, { lightness: 21 }] },
                        {
                            featureType: 'poi.park',
                            elementType: 'geometry',
                            stylers: [{ color: '#f4f5f6' }, { lightness: 21 }],
                        },
                        {
                            featureType: 'road.highway',
                            elementType: 'geometry.fill',
                            stylers: [{ color: '#ffffff' }, { lightness: 17 }],
                        },
                        {
                            featureType: 'road.highway',
                            elementType: 'geometry.stroke',
                            stylers: [{ color: '#ffffff' }, { lightness: 29 }, { weight: 0.2 }],
                        },
                        {
                            featureType: 'road.arterial',
                            elementType: 'geometry',
                            stylers: [{ color: '#ffffff' }, { lightness: 18 }],
                        },
                        {
                            featureType: 'road.local',
                            elementType: 'geometry',
                            stylers: [{ color: '#ffffff' }, { lightness: 16 }],
                        },
                        {
                            featureType: 'transit',
                            elementType: 'geometry',
                            stylers: [{ color: '#f2f2f2' }, { lightness: 19 }],
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ lightness: 17 }, { color: '#e3e7e9' }],
                        },
                        { featureType: 'water', elementType: 'labels', stylers: [{ visibility: 'off' }] },
                    ]
                },
            },
        },
        data:{ 
            center: {lat:-32.902252, lng:151.731147},
            zoom: 13,
            infoWindowPos: null,
            infoWinOpen: false,
            currentMidx: null, 
            infoOptions: {
                content: '',
                //optional: offset infowindow so it visually sits nicely on top of our marker
                pixelOffset: {
                  width: 0,
                  height: -35
                }
              },


            listing: dataVenues,    
            maxItemsToDisplay: 2,
            backMaxItemsToDisplay: 0,
            additionalItemsToDisplay: 6, 
            showLoader: false,
            facilities:[
                {
                    "name":"Accessible",
                    "label": "Accessible"
                },
                {
                    "name":"OnsiteCatering",
                    "label": "In-house catering"
                },
                {
                    "name":"Licenced",
                    "label": "Licenced"
                },
                {
                    "name":"Wifi",
                    "label": "Wifi"
                },
                {
                    "name":"OnsiteParking",
                    "label": "On-site parking"
                },
                {
                    "name":"InHouseAV",
                    "label": "In-house A/V"
                },
                {
                    "name":"ExhibitionSpace",
                    "label": "Exhibition space (with loading dock)"
                }
            ],
            venueTypes:[
                {
                    "name":"BanquetPax",
                    "label": "Banquet"
                },
                {
                    "name":"CocktailPax",
                    "label": "Cocktail"
                },
                {
                    "name":"TheatrePax",
                    "label": "Theatre"
                },
                {
                    "name":"ClassroomPax",
                    "label": "Classroom"
                },
                {
                    "name":"BoardroomPax",
                    "label": "Boardroom"
                },
                {
                    "name":"UShapePax",
                    "label": "U-Shape"
                }
            ],
            selectedTag:[],
            attendees: '',
            selectedType: 'all', 
            filters:[] 
        },
        
        
        computed: { 
            google: VueGoogleMaps.gmapApi,
            mapOptions(){
                return {
                    //styles: this.mapStyles,
                    streetViewControl: false,
                    mapTypeControl: false,
                }
            },
            markerIcon(){
                const { google } = this;
                const gmaps = google?.maps;
                if(!gmaps) return { url: pinSvg };
                return { url:pinSvg, scaledSize: new gmaps.Size(48, 48), optimized: false }
            },
            venuesRemap() {
                function getNum(val) {
                    val = +val || 0
                    return val;
                 } 
                return this.listing.map(listing => ({
                    ...listing,
                    image: listing.VenueImage.replace('~', ''),
                    criteria: {
                        Accessible : listing.Accessible,
                        AccommodationPax : parseFloat(listing.AccommodationPax) >= 1 ? true : false || false ,
                        BanquetPax : parseFloat(listing.BanquetPax) >= 1 ? true : false || false ,
                        CocktailPax : parseFloat(listing.CocktailPax) >= 1 ? true : false || false ,
                        ClassroomPax : parseFloat(listing.ClassroomPax) >= 1 ? true : false || false ,
                        BoardroomPax : parseFloat(listing.BoardroomPax) >= 1 ? true : false || false ,
                        UShapePax : parseFloat(listing.UShapePax) >= 1 ? true : false || false ,
                        ExhibitionSpace: listing.ExhibitionSpace >= 1 ? true : false || false ,
                        InHouseAV: listing.InHouseAV >= 1 ? true : false || false ,
                        Licenced: listing.Licenced >= 1 ? true : false || false ,
                        OnsiteCatering: listing.OnsiteCatering >= 1 ? true : false || false ,
                        OnsiteParking: parseFloat(listing.OnsiteParking) >= 1 ? true : false || false ,
                        TheatrePax: parseFloat(listing.TheatrePax) >= 1 ? true : false || false ,
                        Wifi: listing.Wifi,
                    },
                    attendees: getNum(listing.BanquetPax) + getNum(listing.CocktailPax) + getNum(listing.TheatrePax) + getNum(listing.AccommodationPax),
                    location: {lat: parseFloat(listing.MapPicker.split(',')[0]), lng: parseFloat(listing.MapPicker.split(',')[1]) },
                    mapTitle: `<div class="map-bubble" style="width:348px;"><a href="${listing.DetailsUrl}"><div class="card-body" style="padding-top:0;">
                    <h3 class="card-title">${listing.BusinessName}</h3>
                    <h2 class="card-tag">${listing.Suburb}</h2> 
                    <img src="${listing.VenueImage}" alt="${listing.BusinessName}" class="card-img-top">
                    <p class="card-text d-none d-md-block mb-2" style="font-size:14px;">${listing.Description}</p>
                    <button class="btn btn-red" style="width:100%">Venue Details</button>
                  </div></a></div>`, 
                    
                }))
            }, 
            filteredListing: function () {
                this.showLoader = true; 
                let posts = this.venuesRemap 
                let labels = this.selectedTag 
                let type = this.selectedTag 

                let labelsObject = {};
                labels.forEach(label => {
                    labelsObject[label] = true;
                }) 
                var query = labelsObject; 

                const perfEntries = performance.getEntriesByType('navigation');
                let backfilter = localStorage.getItem('backfilter');

                if ((perfEntries.length && perfEntries[0].type !== 'back_forward') || (backfilter == 'true')) {
                    localStorage.setItem('selectedType', this.selectedType);
                    localStorage.setItem('attendees', this.attendees);
                    localStorage.setItem('selectedTag', this.selectedTag);
                } else {
                    localStorage.setItem('backfilter', true);
                }
     

                function search(item){
                    return Object.keys(this).every((key) => item.criteria[key] === this[key]);
                }

                let labelsType = {};
                type.forEach(label => {
                    labelsType[label] = true;
                }) 
                var queryType = labelsType;
                
                function searchType(item){
                    return Object.keys(this).every((key) => item.criteria[key] === this[key]);
                }
                
                 
                switch (this.selectedType) {
                  case "CocktailPax":
                  case "BanquetPax":
                  case "TheatrePax":
                  case "ClassroomPax":
                  case "BoardroomPax":
                  case "UShapePax":
                    return posts
                      .filter(searchType, queryType)
                      .sort(
                        (b, a) => a[this.selectedType] - b[this.selectedType]
                      )
                      .slice(0, this.maxItemsToDisplay);
                  default:
                    break;
                }


                if(this.attendees){
                    posts = posts.filter(p => {
                        if (p.attendees !== null) {
                            return p.attendees >= this.attendees
                        }
                    })
                    return posts
                }   

                return posts.filter(search, query).sort(function(b,a){return a.IsFeatured - b.IsFeatured }).slice(0, this.maxItemsToDisplay) 

                
            }, 
            filteredListingMap: function () {
                this.showLoader = true; 
                let posts = this.venuesRemap 
                let labels = this.selectedTag 
                let type = this.selectedTag 

                let labelsObject = {};
                labels.forEach(label => {
                    labelsObject[label] = true;
                }) 
                var query = labelsObject; 

                function search(item){
                    return Object.keys(this).every((key) => item.criteria[key] === this[key]);
                }

                let labelsType = {};
                type.forEach(label => {
                    labelsType[label] = true;
                }) 
                var queryType = labelsType;
                
                function searchType(item){
                    return Object.keys(this).every((key) => item.criteria[key] === this[key]);
                }

                switch (this.selectedType) {
                    case "CocktailPax":
                    case 'BanquetPax':
                    case 'TheatrePax':
                    case 'ClassroomPax':
                    case 'BoardroomPax':
                    case 'UShapePax':
                        return posts.filter(searchType, queryType).sort((b,a) => a[this.selectedType] - b[this.selectedType])
                    default:
                        break;
                }

                if(this.attendees){
                    posts = posts.filter(p => {
                        if (p.attendees !== null) {
                            return p.attendees >= this.attendees
                        }
                    })
                    return posts
                }   
               

                return posts.filter(search, query)
            }, 
             
            isMoreItems: function () {
                return this.listing.length > this.maxItemsToDisplay
            }
            
        },

        methods: {
            loadMoreItems(options) {  
                    const total = this.maxItemsToDisplay - 2;
                    const el = this.$el.getElementsByClassName('item-index-'+total)[0]; 
                    
                    if (this.backMaxItemsToDisplay > 0) {
                        this.maxItemsToDisplay = this.backMaxItemsToDisplay;
                        this.backMaxItemsToDisplay = 0;
                    } else {
                       this.maxItemsToDisplay = parseInt(this.maxItemsToDisplay) + parseInt(this.additionalItemsToDisplay); 
                    }
                    
                    localStorage.setItem('maxItemsToDisplay', this.maxItemsToDisplay);

                    if ((el) && (total > 0)) {
                        //setTimeout(function(){ el.scrollIntoView(); }, 0);
                        //el.scrollIntoView(options);
                    }                     
        
            },
            loadAllItems() {
                this.maxItemsToDisplay = this.listing.length;
                localStorage.setItem('maxItemsToDisplay', this.maxItemsToDisplay);
                const el = this.$el.getElementsByClassName('item-index-'+total)[0]; 
                if (el) {
                    el.scrollIntoView();
                } 

            }, 
            blur(e) {
                e.target.blur()
            },
            clearAll(){
                this.selectedTag = []; 
            },
            deleteTag: function(e) {
                this.selectedTag.splice(this.selectedTag.indexOf(e), 1); 
            },
            updateCenter(latLng, zoom) {
                this.center = latLng; 
                this.zoom = zoom
            },  
            toggleInfoWindow(marker, idx) {
                this.infoWindowPos = marker.location;
                this.infoOptions.content = marker.mapTitle;
    
                //check if its the same marker that was selected if yes toggle
                if (this.currentMidx == idx) {
                  this.infoWinOpen = !this.infoWinOpen; 
                }
                //if different marker set infowindow to open and reset current marker index
                else {
                  this.infoWinOpen = true;
                  this.currentMidx = idx;
    
                }
              },
            removeTilde(str) {
                let url = str
                if (str.includes('~')) {
                    url = str.replace('~', '')
                }
                return url
            }, 
        },
        mounted() {  
            const perfEntries = performance.getEntriesByType('navigation');
            if (perfEntries.length && perfEntries[0].type === 'back_forward') {

                var maxDisplay = localStorage.getItem('maxItemsToDisplay');
                if (typeof(maxDisplay) !== undefined) {
                    this.backMaxItemsToDisplay = maxDisplay;
                }
                var selectedType = localStorage.getItem('selectedType');
                var attendees = localStorage.getItem('attendees');

                var selectedTag = localStorage.getItem('selectedTag');

                if (typeof(selectedType) !== undefined) {
                    this.selectedType = selectedType;
                }
                if (typeof(attendees) !== undefined) {
                    this.attendees = attendees;
                }

                if (typeof(selectedTag) !== undefined) {
                    if (selectedTag.split(',').length > 0) {
                        selectedTag.split(',').forEach(item => {
                            if (item !== ""){
                               this.selectedTag.push( item ); 
                            }
                        });                       
                    }

                }

            }

            localStorage.setItem('backfilter', false);

            this.loadMoreItems()  
           
                $('.toggle-pagelock').on('click', function(){
                    $('body').addClass('no-scroll');
                   })
                $('.toggle-pageunlock').on('click', function(){
                    $('body').removeClass('no-scroll');
                   }) ;
        }
         
    })

   

}

 