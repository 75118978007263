import objectFitImages from 'object-fit-images'

export default function lazyload() {
    let images = [...document.querySelectorAll('.lazy-image')]
    //console.log(images)

    // run lazyload for quicklinks regardless of if in scroll view or now
    let quicklinks = [...document.querySelectorAll('.quicklinks-link')]
    quicklinks.forEach(function(item) {
        let image = item.querySelector('.lazy-image');
        image.src = image.getAttribute('data-src');
        image.classList.add('loaded');
    });

    if (!('IntersectionObserver' in window)) {
        console.log('intersectionObserver not in window')
        for (var i = 0; i < images.length; i++) {
            images[i].src = images[i].getAttribute('data-src')
            images[i].classList.add('loaded')
            console.log(images[i].src)
            //String.replace(/~/g, ' ')
            
        }
        // run object-fit polyfill for ie11
        objectFitImages()
    } else {
        function onIntersection(imageEntites) {
            imageEntites.forEach(image => {
                
                if (image.isIntersecting) {
                    // console.log(image)
                    observer.unobserve(image.target)
                    image.target.src = image.target.dataset.src
                    image.target.onload = () => image.target.classList.add('loaded')
                }
            })
        }

        const interactSettings = {
            root: document.querySelector('#page'),
            rootMargin: '0px'
        }

        let observer = new IntersectionObserver(onIntersection, interactSettings)
        images.forEach(image => observer.observe(image))
    }
}
