import $ from 'jquery'

let viewportWidth = $(window).innerWidth()
let viewportHeight = $(window).innerHeight()

let map
let divPosition
let currentLocation

let populateMiniMap = function (location) {
    let map
    map = new google.maps.Map(
        document.getElementById('map'), { zoom: 15, center: location, disableDefaultUI: true, streetViewControl: false });
    let marker = new google.maps.Marker({ optimized: false, position: location, map: map });
}

/**
 * Returns the Popup class.
 *
 * The Popup class can only be defined after
 * google.maps.OverlayView is defined, when the Maps API is loaded.
 * This function should be called by initMap.
 */
function createPopupClass() {
    /**
     * A customized popup on the map.
     * @param {!google.maps.LatLng} position
     * @param {!Element} content The bubble div.
     * @constructor
     * @extends {google.maps.OverlayView}
     */
    function Popup(position, content) {
        this.position = position

        content.classList.add('popup-bubble')

        // This zero-height div is positioned at the bottom of the tip.
        this.containerDiv = document.createElement('div')
        this.containerDiv.classList.add('popup-container')
        this.containerDiv.appendChild(content)

        // Optionally stop clicks, etc., from bubbling up to the map.
        google.maps.OverlayView.preventMapHitsAndGesturesFrom(this.containerDiv)
    }
    // ES5 magic to extend google.maps.OverlayView.
    Popup.prototype = Object.create(google.maps.OverlayView.prototype)

    /** Called when the popup is added to the map. */
    Popup.prototype.onAdd = function () {
        this.getPanes().floatPane.appendChild(this.containerDiv)
    }

    /** Called when the popup is removed from the map. */
    Popup.prototype.onRemove = function () {
        if (this.containerDiv.parentElement) {
            this.containerDiv.parentElement.removeChild(this.containerDiv)
        }
    }

    /** Called each frame when the popup needs to draw itself. */
    Popup.prototype.draw = function () {
        // console.log("Draw this.position=", this.position)

        divPosition = this.getProjection().fromLatLngToDivPixel(this.position)



        // Hide the popup when it is far out of view.
        var display =
            Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ?
                'block' :
                'none'

        if (display === 'block') {
            this.containerDiv.style.left = divPosition.x + 'px'
            this.containerDiv.style.top = divPosition.y + 'px'
        }
        if (this.containerDiv.style.display !== display) {
            this.containerDiv.style.display = display
        }
    }

    Popup.prototype.checkVisibility = function () {
        console.log("checkVisibility ----> divPosition ", divPosition, "currentLocation lat=", currentLocation.lat, "lng=", currentLocation.lng)
        console.log("Map center:", map.getCenter(), map.getCenter().lat(), map.getCenter().lng())
        console.log("Map center in pixels:", this.getProjection().fromLatLngToDivPixel(map.getCenter()))

        let popupHeight = 372
        let popupWidth = 328
        let popupMarginBottom = 52
        let filtersBar = 65
        let verticalSpacing = 50
        let horizontalSpacing = 30

        let centerCoords = this.projection.fromLatLngToContainerPixel(map.getCenter())

        if (divPosition.y - popupHeight - popupMarginBottom < -(viewportHeight / 2) + filtersBar) {
            let negativeYpos = (divPosition.y - popupHeight - popupMarginBottom) - - ((viewportHeight / 2) + filtersBar)
            centerCoords.y += (negativeYpos - popupMarginBottom - filtersBar - verticalSpacing)

            let newDest = this.projection.fromContainerPixelToLatLng(centerCoords)
            map.panTo(newDest)
        }
        if (divPosition.x + (popupWidth / 2) > (viewportWidth / 2)) {
            let postiveXpos = divPosition.x + (popupWidth / 2) - (viewportWidth / 2) + horizontalSpacing

            centerCoords.x += postiveXpos
            let newDest = this.projection.fromContainerPixelToLatLng(centerCoords)
            map.panTo(newDest)
        }
        if (divPosition.x - (popupWidth / 2) < -(viewportWidth / 2)) {
            let negativeXpos = divPosition.x - (popupWidth / 2) + (viewportWidth / 2) - horizontalSpacing

            centerCoords.x += negativeXpos
            let newDest = this.projection.fromContainerPixelToLatLng(centerCoords)
            map.panTo(newDest)
        }
    }

    return Popup
}

export function PopulateMap(things) {
    let popup, Popup
    let popupList = []

    // lat: -32.92774815965193, lng: 151.77214039470536 Newcastle TOWNHALL
    let defaultLoc = { lat: -32.902252, lng: 151.731147 }
    map = new google.maps.Map(
        document.getElementById('map'), { zoom: 12, center: defaultLoc, streetViewControl: false })
    Popup = createPopupClass()

    // let popupContainer = document.getElementById('content')
    let popupContainer = document.createElement("div")
    popupContainer.setAttribute("id", "markerPopup")
    popupContainer.setAttribute("class", "map-card")
    document.getElementById("mapPopup").appendChild(popupContainer)

    things.forEach(item => { 
        if($('#venues').length){
            let loc = { lat: Number(item.Latitude), lng: Number(item.Longitude) }
            let marker = new google.maps.Marker({ optimized: false, position: loc, map: map })
            marker.addListener('click', function () {

                popupContainer.innerHTML = `<a href="${item.DetailsUrl}"><div class="card-body" style="padding-top:0;">
                <h2 class="card-tag">${item.Suburb}</h2> 
                <h3 class="card-title">${item.BusinessName}</h3>
                <img src="${item.TeaserImage}" alt="Card image cap" class="card-img-top">
                <p class="card-text d-none d-md-block mb-2" style="font-size:14px;">${item.Description}</p>
                <button class="btn btn-red" style="width:100%">Venue Details</button>
              </div></a>`
    
                popup = new Popup(
                    new google.maps.LatLng(loc.lat, loc.lng),
                    popupContainer)
    
                popupList.push(popup)
                popup.setMap(map)
    
                currentLocation = loc
    
                setTimeout(() => {
                    popup.checkVisibility()
                }, 100)
            })
        }else{
            let loc = { lat: Number(item.boundary.split(",")[0]), lng: Number(item.boundary.split(",")[1]) }
            let marker = new google.maps.Marker({ optimized: false, position: loc, map: map })
            marker.addListener('click', function () {

                popupContainer.innerHTML = `<a href="${item.detailsUrl}"><img src="${item.image_src}" alt="Card image cap" class="card-img-top">
                    <div class="card-body">
                      <h2 class="card-tag">${item.classificationsDescriptions}</h2> 
                      <h3 class="card-title">${item.name}</h3>
                      <p class="card-text d-none d-md-block">${item.description}</p>
                    </div></a>`
    
                popup = new Popup(
                    new google.maps.LatLng(loc.lat, loc.lng),
                    popupContainer)
    
                popupList.push(popup)
                popup.setMap(map)
    
                currentLocation = loc
    
                setTimeout(() => {
                    popup.checkVisibility()
                }, 100)
            })
        } 
    })

    google.maps.event.addListener(map, "click", function (event) {
        popup.setMap(null)
    })
}


export function InitMap() {
    var hasMiniLoc = false;
    if (typeof window.locationCoords != 'undefined' && window.locationCoords != "") {
        console.log('Google map requested, location: ', window.locationCoords, typeof window.locationCoords)
        populateMiniMap(window.locationCoords)
        hasMiniLoc = true;
    }

    if (typeof window.vueListing != undefined && window.vueListing != null) {
        if (typeof window.vueListing.listing != undefined && typeof window.vueListing.listing != 'undefined') {
            if (window.vueListing.listing.length > 0) {
                PopulateMap(window.vueListing.filteredListing)
            }
        }
    }

    if (((window.EventVenueAddressLine1 && window.EventVenueAddressLine1.length > 0) || (window.EventVenueAddressLine2 && window.EventVenueAddressLine2.length > 0 && window.EventVenueSuburb.length > 0)) && (!hasMiniLoc)) {
        var address = window.EventVenueAddressLine1 || window.EventVenueAddressLine2.length
        var addressLocation = address + ', ' + window.EventVenueSuburb + ',Australia'

        console.log('Google map requested, location: ', addressLocation)

        var geocoder = new google.maps.Geocoder()
        geocoder.geocode({ 'address': addressLocation }, function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
                var location = {"lat": results[0].geometry.location.lat(), "lng": results[0].geometry.location.lng()}
                console.log(location)
                populateMiniMap(location)
            }
        }) 
    }
}